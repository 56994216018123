import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";
import moment from "moment";
import axios from "axios";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";

import { Button } from "@mantine/core";

import Page_ClientOrder_Order from "../Page/Page_ClientOrder_Order";
import Page_ClientOrder_OrderTemplate from "../Page/Page_ClientOrder_OrderTemplate";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function Page_ClientOrder() {
  let { id } = useParams();

  // ------------------------------------------------- DB

  //
  const [DB_Order, setDB_Order] = useState([]);
  const get_DB_Order_byUUID = (_UUID) => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_vlerp__order_log__byUUID`, {
        UUID: _UUID,
      })
      .then((res) => {
        setDB_Order(res.data);
        console.log("get_DB_Order_byUUID", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //
  const [DB_OrderTemplate, setDB_OrderTemplate] = useState([]);
  const get_DB_OrderTemplate_byUUID = (_UUID) => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_vlerp__order_template__byUUID`, {
        UUID: _UUID,
      })
      .then((res) => {
        setDB_OrderTemplate(res.data);
        console.log("get_DB_OrderTemplate_byUUID", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const NavigateOrderComponent = () => {
    if (DB_Order?.length > 0) {
      return <Page_ClientOrder_Order p_data={DB_Order} />;
    }

    if (DB_OrderTemplate?.length > 0) {
      return <Page_ClientOrder_OrderTemplate p_data={DB_OrderTemplate} />;
    }

    if (DB_Order?.length == 0 && DB_OrderTemplate?.length == 0) {
      return "Order Not Found";
    }
  };

  useEffect(() => {
    get_DB_Order_byUUID(id);
    get_DB_OrderTemplate_byUUID(id);
  }, []);

  //git commit -m "v1.0.3, with vlc a0.0.1, vlerp v1.0.4"

  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <br />
      {/* uuid :{id}
      <br />
      <h4>{"Order length: " + DB_Order?.length}</h4>
      <h4>{"Order Template Length : " + DB_OrderTemplate?.length}</h4> */}
      <br />
      {/* http://localhost:3000/#/order/asdasdasdad123123 */}
      <NavigateOrderComponent />
    </>
  );
}
