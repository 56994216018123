import { UserContext } from "./UserContext";
import { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";

// ------------------------------------------------- router
import {
  HashRouter,
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";

import Page_404 from "./Page/Page_404";
import Page_Login from "./Page/Page_Login";
import Page_Main from "./Page/Page_Main";
import Page_UserProfile from "./Page/Page_UserProfile";

import Page_MyOrder from "./Page/Page_MyOrder";

import Page_Order_1_CreateOrder from "./Page/Page_Order_1_CreateOrder";
import Page_Order_2_PrepareOrder from "./Page/Page_Order_2_PrepareOrder.js";
import Page_Order_3_ReadyTo3PL from "./Page/Page_Order_3_ReadyTo3PL";
import Page_Order_4_OrderComplete from "./Page/Page_Order_4_OrderComplete";

import Page_Client_Order_1_CreateOrder from "./Page/Page_Client_Order_1_CreateOrder";
import Page_ClientOrder from "./Page/Page_ClientOrder";
import Page_ClientInvoice from "./Page/Page_ClientInvoice";

import "./Css/CSS_Page_Login.css";
import "./Css/CSS_Page_Order_1_CreateOrder.css";
import "./Css/CSS_Global.css";
import "./Css/CSS_NavBar.css";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

const parrent_DB_userinfo = [];
const fakeAuth = {
  isAuthenticated: false,
  authenticate(cb) {
    this.isAuthenticated = true;
    setTimeout(cb, 100);
  },
  signout(cb) {
    this.isAuthenticated = false;
    setTimeout(cb, 100);
  },
};

function ProtectedRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return fakeAuth.isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
}

function App() {
  // --------------------------------------- use context
  const contextValue = useRef("NA");

  // get version
  const getVersion = async () => {
    const x = await axios
      .get(`${REACT_APP_ECLIPSE_API}/get_vlerp__version`)
      .then((res) => {
        if (localStorage.getItem("ver") === null) {
          localStorage.setItem("ver", res.data[0].value);

          window.location.reload();
        }
        if (localStorage.getItem("ver") !== res.data[0].value) {
          localStorage.setItem("ver", res.data[0].value);
          alert("New Update Available ! " + res.data[0].value);

          window.location.reload();
        }
      });
  };

  useEffect(() => {
    getVersion();
  }, []);

  return (
    <>
      <UserContext.Provider value={{ contextValue }}>
        <HashRouter hashType="slash">
          <Switch>
            <Route
              exact
              path="/"
              component={() => (
                <Page_Login
                  parent_fakeAuth={fakeAuth}
                  parrent_DB_userinfo={parrent_DB_userinfo}
                />
              )}
            />
            <ProtectedRoute
              exact
              path="/main"
              component={() => (
                <Page_Main parrent_DB_userinfo={parrent_DB_userinfo} />
              )}
            />
            <ProtectedRoute
              exact
              path="/userprofile"
              component={Page_UserProfile}
            />

            <ProtectedRoute exact path="/MyOrder" component={Page_MyOrder} />

            <Route exact path="/order/:id" component={Page_ClientOrder} />
            <Route exact path="/invoice/:id" component={Page_ClientInvoice} />
            {/* http://localhost:3000/#/order/f61245c2ac0b11ec8618d0278801e8fd */}

            <Route exact path="*" component={Page_404} />
          </Switch>
        </HashRouter>
      </UserContext.Provider>
    </>
  );
}

export default App;
