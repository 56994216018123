import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

import { FcGoogle as GoogleIcon } from "react-icons/fc";
import { FaFacebookSquare as FacebookIcon } from "react-icons/fa";

// -------------------------------------------------  firebase - 1/2
import { authentication } from "../firebase-config";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

import { UserContext } from "../UserContext";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function COM_NavBar() {
  // -------------------------------------------------  firebase - 2/2
  // -------------------------------------------------  firebase - 2/2
  const [firebase_userInfo, setFirebase_userInfo] = useState({});
  const [firebase_user_SignedIn, setFirebase_user_SignedIn] = useState(false);
  const [DB_user_Authenticated, setDB_user_Authenticated] = useState(false);

  onAuthStateChanged(authentication, (currentUser) => {
    setFirebase_userInfo(currentUser);

    if (authentication?.currentUser != null) {
      setFirebase_user_SignedIn(true);
      // console.log("# user Signed in", firebase_user_SignedIn.current);
    } else {
      setFirebase_user_SignedIn(false);
      // console.log("# user Signed in", firebase_user_SignedIn.current);
    }
  });

  const singInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(authentication, provider)
      .then((re) => {
        setFirebase_userInfo(re);
        // console.log("re", re);
      })
      .catch((err) => {
        // console.log("re-err", err);
      });
  };

  const signInWithFacebook = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(authentication, provider)
      .then((re) => {
        setFirebase_userInfo(re);
        // console.log("re", re);
      })
      .catch((err) => {
        // console.log("re-err", err);
      });
  };

  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  return (
    <>
      <div className="NavBar_Container global_flex_row_cc">
        <div
          className="NavBar_Btn"
          onClick={() => (window.location.href = "#/MyOrder")}
        >
          <div>My Order</div>
        </div>

        {firebase_user_SignedIn == true ? (
          <>
            <div
              className="btn_logout global_flex_column_cc "
              onClick={() => {
                signOut(authentication);
                setTimeout(() => {
                  window.location.href = "/";
                  alert("Signed Out Successully!");
                }, 1000);
              }}
            >
              <img
                className="img_UserProfile_small"
                src={`${firebase_userInfo?.photoURL}`}
              ></img>

              <div>LOGOUT</div>
            </div>
          </>
        ) : (
          <>
            <div
              className="btn_normal global_flex_row_cc"
              onClick={() => {
                singInWithGoogle();
              }}
            >
              <GoogleIcon
                style={{ padding: "0px 10px 0px 0px", fontSize: "30px" }}
              />
              <div>Login with Google</div>
            </div>

            <div
              className="btn_normal global_flex_row_cc"
              onClick={() => {
                signInWithFacebook();
              }}
            >
              <FacebookIcon
                style={{
                  color: "#4064AC",
                  padding: "0px 10px 0px 0px",
                  fontSize: "30px",
                }}
              />
              <div>Login with Facebook</div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
