import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";
import moment from "moment";
import axios from "axios";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";

import { FcGoogle as GoogleIcon } from "react-icons/fc";
import { AiFillMail } from "react-icons/ai";
import { FaFacebookSquare as FacebookIcon } from "react-icons/fa";
// -------------------------------------------------  firebase - 1/2
import { authentication } from "../firebase-config";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from "@mui/material";

import COM_NavBar from "../Component/COM_NavBar";
import COM_FileUpload from "../Component/COM_FileUpload";

import { UserContext } from "../UserContext";
import { WindowSharp } from "@mui/icons-material";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function Page_ClientOrder_OrderTemplate({ p_data }) {
  let { id } = useParams();

  // -------------------------------------------------  firebase - 2/2
  const [firebase_userInfo, setFirebase_userInfo] = useState({});
  const [firebase_user_SignedIn, setFirebase_user_SignedIn] = useState(false);
  const [DB_user_Authenticated, setDB_user_Authenticated] = useState(false);

  onAuthStateChanged(authentication, (currentUser) => {
    setFirebase_userInfo(currentUser);

    if (authentication?.currentUser != null) {
      setFirebase_user_SignedIn(true);
      // console.log("# user Signed in", firebase_user_SignedIn.current);
    } else {
      setFirebase_user_SignedIn(false);
      // console.log("# user Signed in", firebase_user_SignedIn.current);
    }
  });

  const singInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(authentication, provider)
      .then((re) => {
        setFirebase_userInfo(re);
        // console.log("re", re);
      })
      .catch((err) => {
        // console.log("re-err", err);
      });
  };

  const signInWithFacebook = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(authentication, provider)
      .then((re) => {
        setFirebase_userInfo(re);
        // console.log("re", re);
      })
      .catch((err) => {
        // console.log("re-err", err);
      });
  };

  // ------------------------------------------------- DB

  //
  const [DB_Order, setDB_Order] = useState([]);
  const get_DB_Order_byUUID = (_UUID) => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_vlerp__order_log__byUUID`, {
        UUID: id,
      })
      .then((res) => {
        setDB_Order(res.data);
        console.log("_UUID:id", id);
        console.log("get_DB_Order_byUUID", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // get user info by uid
  const [DB_ClientInfo, setDB_ClientInfo] = useState([]);
  const get_DB_ClientInfo_byid = () => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_vlerp__client_info__byid`, {
        client_uuid: firebase_userInfo.uid,
      })
      .then((res) => {
        setDB_ClientInfo(res.data[0]);

        if (res.data[0] != undefined) {
          // client found
          console.log("# Client Found!", res.data[0]);

          // assign value
          setSelected_ClientContact(res.data[0].client_contact);
          setSelected_ClientAddress(res.data[0].client_address);
        } else {
          // client not found
          //  new client
          console.log("# Client NOT Found!, Add new Client");

          // add_DB_ClientInfo_byid();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // --------------------------------------- function
  const calc_TotalQty = () => {
    return DB_Order?.reduce(
      (acc, x) => acc + parseFloat(x?.total_selected_ItemQty),
      0
    );
  };
  const calc_SubTotal = () => {
    return DB_Order?.reduce(
      (acc, x) =>
        acc +
        parseFloat(x?.total_selected_ItemQty) *
          parseFloat(x?.selected_ItemPrice),
      0
    ).toFixed(2);
  };
  const calc_CourierFee = () => {
    return parseFloat(DB_Order[0]?.courier_fee).toFixed(2);
  };

  const calc_COD = () => {
    return parseFloat(DB_Order[0]?.cod_fee).toFixed(2);
  };

  const calc_GrandTotal = () => {
    return (
      parseFloat(calc_SubTotal()) +
      parseFloat(calc_CourierFee()) +
      parseFloat(calc_COD())
    ).toFixed(2);
  };

  // --------------------------------------- Radio
  const [rb_CourierFees, setRb_CourierFees] = React.useState();

  const hc_Rb_CourierFees = (event) => {
    setRb_CourierFees(event.target.value);
  };

  // --------------------------------------- Global variable

  const [selected_ClientContact, setSelected_ClientContact] = useState("");
  const [selected_ClientAddress, setSelected_ClientAddress] = useState("");

  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  useEffect(() => {
    console.log("contextValue", contextValue);
    get_DB_Order_byUUID();
  }, []);

  // if user Signed in Firebase Auth
  useEffect(() => {
    if (firebase_user_SignedIn) {
      console.log("# firebase_user_SignedIn", firebase_user_SignedIn);

      console.log("# firebase_userInfo", firebase_userInfo);

      // if signin firebase, get user info, if empty create new, get user info again
      if (firebase_user_SignedIn == true) {
        get_DB_ClientInfo_byid();
      }
    }
  }, [firebase_user_SignedIn]);

  return (
    <>
      <COM_NavBar />
      <br />
      <br />
      <br />
      <br />
      <br />
      {/* {console.log("DB_Order", DB_Order)} */}
      <h3> {`${id}`} </h3>

      <div className="po_1_co__itemContainer">
        <div className="global_grid_2column_container_2080">
          <h1>{`Invoice `} </h1>
          <h1>---</h1>
          <h3>Order ID</h3>
          <h3> {`${DB_Order[0]?.order_id}`} </h3>
        </div>
        <br />

        <div className="global_grid_2column_container_2080">
          <h3>Template</h3>
          <h1>{DB_Order[0]?.order_template}</h1>
          <h3>Promotion Date</h3>
          <div>
            <h3>{`${DB_Order[0]?.order_template_from} - ${DB_Order[0]?.order_template_until}`}</h3>
            {DB_Order[0]?.DurationRemain}
          </div>
          <h3>Referer Name</h3>
          <h3>{`[${DB_Order[0]?.referer_id}] ${DB_Order[0]?.referer_name}`}</h3>
        </div>
        <br />
        <div className="global_grid_2column_container_2080">
          <div>
            <h3>Client Name</h3>
            <div>{`${
              firebase_user_SignedIn == true
                ? `Client ID : ${firebase_userInfo.uid}`
                : "<Login Required>"
            }`}</div>
          </div>
          <div>
            <h3>{`${
              firebase_user_SignedIn == true
                ? firebase_userInfo.displayName
                : "<Login Required>"
            }`}</h3>
          </div>
          <h3>Client Email</h3>
          <h3>{DB_Order[0]?.client_email}</h3>
          <h3>Client Contact</h3>
          <h3>{DB_Order[0]?.client_contact}</h3>

          <h3>Client Mailing Address 1</h3>
          <h3>{DB_Order[0]?.client_address}</h3>
          <h3>Client Mailing Address 2</h3>
          <h3>{DB_Order[0]?.client_address2}</h3>
          <h3>Client Mailing State</h3>
          <h3>{DB_Order[0]?.client_state}</h3>
          <h3>Client Mailing City</h3>
          <h3>{DB_Order[0]?.client_city}</h3>
          <h3>Client Mailing Country</h3>
          <h3>{DB_Order[0]?.client_country}</h3>
          <h3>Client Mailing Postcode</h3>
          <h3>{DB_Order[0]?.client_postcode}</h3>
        </div>
        <br />
        <div className="global_grid_2column_container_2080">
          <h3>Sub Total</h3>
          <h3>{`RM ${calc_SubTotal()}`}</h3>
          <h3>Courier Fees</h3>
          <h3>{`RM ${calc_CourierFee()}`}</h3>
          <h3>Grand Total</h3>
          <h3>{`RM ${calc_GrandTotal()}`}</h3>
          <br />
        </div>
        <br />

        <div className="global_grid_2column_container_2080">
          <h3>Payment Status</h3>
          <h3>
            {DB_Order[0]?.payment_status == "false" ||
            DB_Order[0]?.payment_status == null ? (
              <div style={{ color: "red" }}>FALSE</div>
            ) : (
              <div style={{ color: "green" }}>TRUE</div>
            )}
          </h3>
          <h3>Payment Date</h3>
          <h3>{DB_Order[0]?.payment_dt}</h3>
          {DB_Order[0]?.payment_status == "false" ||
          DB_Order[0]?.payment_status == null ? (
            <>
              <h3>Make Payment</h3>

              <div
                className="btn_normal "
                onClick={() => {
                  window.open(DB_Order[0]?.billplz_url);
                }}
              >
                <div className="padding_30">
                  <b>Online Banking - Payment Gateway</b>
                </div>
              </div>
            </>
          ) : (
            <>
              <h3>Payment By</h3>
              <h3>{DB_Order[0]?.payment_by}</h3>
              <h3>Payment Info</h3>
              <div>
                <div
                  className="btn_normal"
                  onClick={() => {
                    window.open(DB_Order[0]?.payment_info);
                  }}
                >
                  <div className="padding_30">
                    <h3> CHECK PAYMENT INFO</h3>
                    {DB_Order[0]?.payment_info}
                  </div>
                </div>
              </div>
            </>
          )}
          <div>
            <h3>(Optional) Upload Payment Receipt</h3>
            <div>Only for those who transfer via ATM or Bank Account</div>
          </div>
          {DB_Order[0] != undefined ? (
            <div className="global_grid_2column_container_5050">
              <COM_FileUpload
                uploadAllow={true}
                maxSizeMB="0.5"
                maxWidthOrHeight="1024"
                buttonName_PostFix={"Receipt (PDF Only)"}
                serverDirPath={"uploads/vlerp/receipt"}
                uploadFileName={`${DB_Order[0]?.order_id}__receipt001`}
                displayWindowHeight="200px"
                fileTpe="pdf" //ie. jpg/png/pdf/mp4
              />
              <COM_FileUpload
                uploadAllow={true}
                maxSizeMB="0.5"
                maxWidthOrHeight="1024"
                buttonName_PostFix={"PHOTO 1 ( Jpg Only ) "}
                serverDirPath={"uploads/vlerp/receipt"}
                uploadFileName={`${DB_Order[0]?.order_id}__receipt002`}
                displayWindowHeight="200px"
                fileTpe="jpg" //ie. jpg/png/pdf/mp4
              />
            </div>
          ) : (
            <div />
          )}
        </div>
        <br />
        <div className="po_1_co__itemContainer">
          <h3>Item(s)</h3>
          <div />
          <div />
          <div className="global_grid_4column_container_cust002">
            <h4 className="global_textAlignCenter">Product Description</h4>
            <div />
            <h4 className="global_textAlignCenter">Price Per Unit (RM)</h4>

            <h4 className="global_textAlignCenter">Order Qty</h4>
            <h4 className="global_textAlignCenter">Sub Total (RM)</h4>

            {/* {console.log("DB_Order", DB_Order)} */}
            {/* item */}
            {DB_Order?.map((x) => (
              <>
                <div className="global_grid_2column_container_cust003">
                  <img
                    style={{ width: "150px" }}
                    src={`${REACT_APP_ECLIPSE_API}/uploads/vlerp/product/${x?.item_id}__product001.jpg`}
                  />

                  <div>
                    <h3>{`[${x?.item_id}] ${x?.item_name}`}</h3>
                    <h5>{x?.item_desc}</h5>
                  </div>
                </div>
                <div />
                <h3 className="global_textAlignCenter">
                  {parseFloat(x?.selected_ItemPrice).toFixed(2)}
                </h3>
                <h3 className="global_textAlignCenter">
                  {parseFloat(x?.total_selected_ItemQty).toFixed(2)}
                </h3>
                {/* <h3>{x?.selected_Item_MinOrderQty}</h3> */}
                <h3 className="global_textAlignCenter">
                  {(
                    parseFloat(x?.selected_ItemPrice) *
                    parseFloat(x?.total_selected_ItemQty)
                  ).toFixed(2)}
                </h3>
              </>
            ))}

            {/* Total Price */}
            <div />
            <div />
            <h3 className="global_textAlignCenter">Sub Total</h3>

            <h3 className="global_textAlignCenter">
              {`${calc_TotalQty()} Item(s)`}
            </h3>
            <h3 className="global_textAlignCenter">
              <u>{`RM ${calc_SubTotal()}`}</u>
            </h3>

            {/* Courier Fees */}
            <div />
            <div />
            <h3 className="global_textAlignCenter">Courier Fee</h3>
            <div />

            <h3 className="global_textAlignCenter">
              {`RM ${calc_CourierFee()}`}
            </h3>
            {/* Courier Fees */}
            <div />
            <div />
            <h3 className="global_textAlignCenter">COD Fee</h3>
            <div />

            <h3 className="global_textAlignCenter">{`RM ${calc_COD()}`}</h3>
            {/* Grand Total  */}
            <div />
            <div />
            <h3 className="global_textAlignCenter">Grand Total</h3>
            <div />

            <h3 className="global_textAlignCenter">
              {`RM ${calc_GrandTotal()}`}
            </h3>
          </div>
        </div>
      </div>
    </>
  );
}
