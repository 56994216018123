import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";
import moment from "moment";
import axios from "axios";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import { UserContext } from "../UserContext";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

const filter = createFilterOptions();

export default function FreeSoloCreateOptionDialog({
  setAC_SelectedData_Referer,
  C_Title,
}) {
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  // ------------------------------------------------- DB

  //
  const [DB_RefererList, setDB_RefererList] = useState([]);
  const get_DB_Referer_List = () => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_vlerp__referer_info__all`)
      .then((res) => {
        setDB_RefererList(res.data);
        console.log("get_DB_Referer_List", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //
  const add_DB_Referer_List = (
    _referer_name,
    _referer_contact,
    _referer_address,
    _referer_gen_by
  ) => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/add_vlerp__referer_info`, {
        referer_name: _referer_name,
        referer_contact: _referer_contact,
        referer_address: _referer_address,
        referer_gen_by: _referer_gen_by,
      })
      .then((res) => {
        setDB_RefererList(res.data);
        console.log("add_DB_Referer_List", res.data);
        if (res.data.affectedRows > 0) {
          alert("New Referer Add Successfully ! ");

          setTimeout(() => {
            get_DB_Referer_List();
            handleClose();
          }, 2000);
        } else {
          alert("Failed to Add New Referer, Please contact Admin !");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // ------------------------------------------------- etc

  const [value, setValue] = React.useState(null);
  const [open, toggleOpen] = React.useState(false);

  const handleClose = () => {
    setDialogValue({
      referer_name: "",
      referer_contact: "",
    });

    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    referer_name: "",
    referer_contact: "",
  });

  const handleSubmit = (event) => {
    console.log("hit");
    event.preventDefault();
    setValue({
      referer_id: selected_RefererID.current,
      referer_name: dialogValue.referer_name,
      referer_contact: parseInt(dialogValue.referer_contact, 10),
      referer_address: dialogValue.referer_address,
    });

    // // update parent
    setAC_SelectedData_Referer({
      referer_name: dialogValue.referer_name,
      referer_contact: parseInt(dialogValue.referer_contact, 10),
      referer_address: "dialogValue.referer_address",
    });

    // add to db
    add_DB_Referer_List(
      dialogValue.referer_name,
      parseInt(dialogValue.referer_contact, 10),
      dialogValue.referer_address,
      contextValue.current.firebase_userInfo.email
    );
  };

  // ------------------------------------------------- Global Variable
  const selected_RefererID = useRef("NA");

  // ------------------------------------------------- useEffect

  useEffect(() => {
    get_DB_Referer_List();
  }, []);

  return (
    <React.Fragment>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                referer_name: newValue,
                referer_contact: "",
              });
              setAC_SelectedData_Referer({
                referer_name: newValue,
                referer_contact: "",
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              referer_name: newValue.inputValue,
              referer_contact: "",
            });
            setAC_SelectedData_Referer({
              referer_name: newValue.inputValue,
              referer_contact: "",
            });
          } else {
            setValue(newValue);
            setAC_SelectedData_Referer(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              referer_name: `Add "${params?.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={DB_RefererList}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          // console.log("option", option.id);
          selected_RefererID.current = option.id;
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.id == undefined
            ? option.referer_name
            : "(" + option.id + ") " + option.referer_name;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => (
          <li {...props}>
            <div>
              <div>{`(${option?.id}) ${option?.referer_name}`}</div>
              <div>{`ref : ${option?.referer_contact}`}</div>
            </div>
          </li>
        )}
        sx={{ width: "100%" }}
        freeSolo
        renderInput={(params) => <TextField {...params} label={C_Title} />}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        // fullScreen
        fullWidth={true}
        maxWidth="xl"
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add a new Referer</DialogTitle>
          <DialogContent>
            <DialogContentText>..</DialogContentText>

            <div className="global_flex_column_cc gap_50">
              <TextField
                fullWidth
                autoFocus
                margin="dense"
                id="name"
                value={dialogValue.referer_name.split(")")[1]}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    referer_name: event.target.value,
                  })
                }
                label="Referer Name"
                type="text"
                variant="standard"
              />
              <TextField
                fullWidth
                margin="dense"
                id="name"
                value={dialogValue.referer_contact}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    referer_contact: event.target.value,
                  })
                }
                label="Referer Contact"
                type="number"
                variant="standard"
              />
              <TextField
                fullWidth
                margin="dense"
                id="name"
                value={dialogValue.referer_address}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    referer_address: event.target.value,
                  })
                }
                label="Referer Addtess"
                type="text"
                variant="standard"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const sampleData = [
  { title: "The Shawshank Redemption", referer_contact: 1994 },
  { title: "The Godfather", referer_contact: 1972 },
];
