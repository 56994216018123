import React from "react";

export default function Page_ClientOrder_Order({ p_data }) {
  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <hr />
      <div className="global_grid_2column_container_2080">
        <h3>Template</h3>
        <h1>{p_data[0]?.order_template}</h1>

        <h3>Client Name</h3>
        <h3>{`${p_data[0]?.client_name}`}</h3>
        <h3>Courier Fees</h3>
        <h3>{`${p_data[0]?.courier_fee}`}</h3>
        <h3>Courier Fees</h3>
        <h3>{`RM ${p_data[0]?.courier_fee}`}</h3>
        <h3>Item(s)</h3>
        <div />
        <div />
        <div className="global_grid_4column_container_cust001">
          <h4>Product Description</h4>
          <h4>Qty</h4>
          <h4>Price (RM)</h4>
          {/* item */}
          {p_data.map((x) => (
            <>
              <div>
                <h3>{`[${x?.item_id}] ${x?.item_name}`}</h3>
                <h5>{x?.item_desc}</h5>
              </div>
              <h3>{x?.selected_ItemQty}</h3>
              <h3>{parseFloat(x?.selected_ItemPrice).toFixed(2)}</h3>
            </>
          ))}
          {/* Total Price */}
          <div />
          <h4>
            {p_data.reduce((acc, x) => acc + parseFloat(x.selected_ItemQty), 0)}
          </h4>
          <h4>
            <u>
              {p_data
                .reduce(
                  (acc, x) =>
                    acc +
                    parseFloat(x.selected_ItemQty) *
                      parseFloat(x.selected_ItemPrice),
                  0
                )
                .toFixed(2)}
            </u>
          </h4>
        </div>
      </div>
    </>
  );
}
