import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "@material-ui/core";
import { Backup, Attachment } from "@material-ui/icons";

import imageCompression from "browser-image-compression";
import { Typography, Tooltip } from "@material-ui/core";

const { REACT_APP_ECLIPSE_API_PUBLIC, REACT_APP_ECLIPSE_API } = process.env;

/*
# Sample code

 <COM_FileUpload
                uploadAllow={true}
                maxSizeMB="0.5"
                maxWidthOrHeight="1024"
                buttonName_PostFix={"PHOTO 1 ( Jpg Only ) "}
                serverDirPath={"uploads/vlerp/receipt"}
                uploadFileName={`${DB_Order[0]?.order_id}__receipt002`}
                displayWindowHeight="200px"
                fileTpe="jpg" //ie. jpg/png/pdf/mp4
              />

*/

export default function COM_FileUpload({
  maxSizeMB,
  maxWidthOrHeight,
  buttonName_PostFix,
  displayWindowHeight,
  uploadAllow,
  serverDirPath,
  uploadFileName,
  fileTpe,
}) {
  const compressImage = async (event, useWebWorker) => {
    const file = event.target.files[0];
    if (file === undefined) return; //exit if no file choosesn
    // console.log("input", file);
    // console.log(
    //   "ExifOrientation",
    //   await imageCompression.getExifOrientation(file)
    // );

    // sent file to server
    const data = new FormData();

    // img type
    if (fileTpe == "jpg" || fileTpe == "png") {
      var options = {
        maxSizeMB: maxSizeMB,
        maxWidthOrHeight: maxWidthOrHeight,
        useWebWorker,
      };
      const output = await imageCompression(file, options);
      // console.log("output", output);

      data.append("path", serverDirPath);
      data.append("name", uploadFileName);
      data.append("file", output);
    } else if (fileTpe == "pdf" || fileTpe == "mp4") {
      data.append("path", serverDirPath);
      data.append("name", uploadFileName);
      data.append("file", file);
    }

    console.log("data", data);

    axios
      .post(`${REACT_APP_ECLIPSE_API}/upload_eclipse_global_attachment`, data)
      .then((res) => {
        console.log(res);
        testUrl_Exists();
      })
      .catch((err) => {
        // console.log(err)
      });
  };

  const testUrl_Exists = () => {
    axios
      .get(
        `${REACT_APP_ECLIPSE_API}/testURLExist?url=${REACT_APP_ECLIPSE_API}/${serverDirPath}/${uploadFileName}.${fileTpe}`
      )
      .then((res) => {
        console.log("testUrl_Exists", res);
        console.log(
          "testUrl",
          `${REACT_APP_ECLIPSE_API}/${serverDirPath}/${uploadFileName}.${fileTpe}`
        );
        setFileExists(false);
        setFileExists(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ---------------------- Global Variable
  const [fileExists, setFileExists] = useState(false);

  useEffect(() => {
    testUrl_Exists();
  }, []);

  return (
    <>
      {fileExists === true ? (
        <div
          style={
            {
              // backgroundColor: "rgba(43, 195, 255,.1)",
            }
          }
          display="flex"
          flexDirection="column"
        >
          <div style={{ position: "relative" }}>
            <Tooltip title="Full View">
              <Button
                fullWidth
                variant="outlined"
                style={{
                  padding: "10px 10px 10px 10px ",
                }}
                onClick={() =>
                  window.open(
                    `${REACT_APP_ECLIPSE_API}/${serverDirPath}/${uploadFileName}.${fileTpe}?dummy=${Math.random()}`
                  )
                }
              >
                <>
                  {fileTpe == "jpg" || fileTpe == "png" ? (
                    <img
                      style={{}}
                      height={displayWindowHeight}
                      src={`${REACT_APP_ECLIPSE_API}/${serverDirPath}/${uploadFileName}.${fileTpe}?dummy=${Math.random()}`}
                    />
                  ) : (
                    <>
                      {fileTpe == "mp4" ? (
                        <video width="400" controls>
                          <source
                            src={`${REACT_APP_ECLIPSE_API}/${serverDirPath}/${uploadFileName}.${fileTpe}`}
                            type="video/mp4"
                          />
                          Your browser does not support HTML video.
                        </video>
                      ) : (
                        <>
                          <div
                            style={{
                              height: displayWindowHeight,
                              // backgroundColor: "red",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <div>PDF FILE UPLOADED</div>
                            <div>VIEW PDF</div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  <div
                    style={{
                      position: "absolute",
                      top: "10%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      backgroundColor: "rgba(43, 195, 255,.5)",

                      boxShadow: "0 8px 32px 0 rgba(43, 195, 255, 0.4 )",
                      backdropFilter: "blur( 11.0px )",
                      "&::webkit-backdrop-filter": {
                        webkitBackdropFilter: "blur( 18.0px )",
                      },
                    }}
                  >
                    {buttonName_PostFix}
                  </div>
                </>
              </Button>
            </Tooltip>
          </div>
          <br />
          {uploadAllow === true ? (
            <>
              <Tooltip title="Upload">
                <Button
                  fullWidth
                  variant="contained"
                  component="label"
                  style={{
                    marginTop: "-40px",
                    borderRadius: "0px",
                    backgroundColor: "rgba(43, 195, 255,.5)",

                    boxShadow: "0 8px 32px 0 rgba(43, 195, 255, 0.4 )",
                    backdropFilter: "blur( 11.0px )",
                    "&::webkit-backdrop-filter": {
                      webkitBackdropFilter: "blur( 18.0px )",
                    },
                  }}
                >
                  <Typography
                    variant="caption"
                    display="block"
                    style={{
                      textAlign: "center",
                      fontSize: "10px",
                      // color: "red",
                    }}
                  >
                    {fileExists === true ? (
                      <>
                        <div>
                          <Backup style={{ marginRight: "10px" }} />
                          Re-Upload :
                        </div>
                        <div> {buttonName_PostFix}</div>
                      </>
                    ) : (
                      <>
                        <div>
                          <Backup style={{ marginRight: "10px" }} />
                          Upload :
                        </div>
                        <div> {buttonName_PostFix}</div>
                      </>
                    )}
                    <input
                      // id="web-worker"
                      type="file"
                      accept={`.${fileTpe}`}
                      hidden
                      onChange={(e) => {
                        compressImage(e, true);
                      }}
                    />
                  </Typography>
                </Button>
              </Tooltip>
            </>
          ) : (
            ""
          )}
        </div>
      ) : (
        <>
          {uploadAllow === true ? (
            <>
              <Tooltip title="Upload">
                <Button
                  fullWidth
                  variant="contained"
                  component="label"
                  style={{
                    // marginTop: "-140px",
                    borderRadius: "0px",
                    backgroundColor: "rgba(43, 195, 255,.5)",

                    boxShadow: "0 8px 32px 0 rgba(43, 195, 255, 0.4 )",
                    backdropFilter: "blur( 11.0px )",
                    "&::webkit-backdrop-filter": {
                      webkitBackdropFilter: "blur( 18.0px )",
                    },
                  }}
                >
                  <Typography
                    variant="caption"
                    display="block"
                    style={{
                      textAlign: "center",
                      fontSize: "10px",
                      // color: "red",
                    }}
                  >
                    {fileExists === true ? (
                      <>
                        <div>
                          <Backup style={{ marginRight: "10px" }} />
                          Re-Upload :
                        </div>
                        <div> {buttonName_PostFix}</div>
                      </>
                    ) : (
                      <>
                        <div>
                          <Backup style={{ marginRight: "10px" }} />
                          Upload :
                        </div>
                        <div> {buttonName_PostFix}</div>
                      </>
                    )}
                    <input
                      // id="web-worker"
                      type="file"
                      accept={`.${fileTpe}`}
                      hidden
                      onChange={(e) => {
                        compressImage(e, true);
                      }}
                    />
                  </Typography>
                </Button>
              </Tooltip>
            </>
          ) : (
            <>
              <Typography
                variant="caption"
                display="block"
                style={{
                  textAlign: "center",
                  // marginTop: "-140px",
                  borderRadius: "0px",
                  backgroundColor: "rgba(43, 195, 255,.5)",

                  boxShadow: "0 8px 32px 0 rgba(43, 195, 255, 0.4 )",
                  backdropFilter: "blur( 11.0px )",
                  "&::webkit-backdrop-filter": {
                    webkitBackdropFilter: "blur( 18.0px )",
                  },
                }}
              >
                NO PICTURE
              </Typography>
            </>
          )}
        </>
      )}
    </>
  );
}
