import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";
import moment from "moment";
import axios from "axios";

import { FcGoogle as GoogleIcon } from "react-icons/fc";
import { AiFillMail } from "react-icons/ai";
import { FaFacebookSquare as FacebookIcon } from "react-icons/fa";
// -------------------------------------------------  firebase - 1/2
import { authentication } from "../firebase-config";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from "@mui/material";

import {
  Select,
  NumberInput,
  Group,
  ActionIcon,
  NumberInputHandlers,
} from "@mantine/core";

import COM_NavBar from "../Component/COM_NavBar";

import { UserContext } from "../UserContext";
import { WindowSharp } from "@mui/icons-material";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function Page_ClientOrder_OrderTemplate({ p_data }) {
  // -------------------------------------------------  firebase - 2/2
  const [firebase_userInfo, setFirebase_userInfo] = useState({});
  const [firebase_user_SignedIn, setFirebase_user_SignedIn] = useState(false);
  const [DB_user_Authenticated, setDB_user_Authenticated] = useState(false);

  onAuthStateChanged(authentication, (currentUser) => {
    setFirebase_userInfo(currentUser);

    if (authentication?.currentUser != null) {
      setFirebase_user_SignedIn(true);
      // console.log("# user Signed in", firebase_user_SignedIn.current);
    } else {
      setFirebase_user_SignedIn(false);
      // console.log("# user Signed in", firebase_user_SignedIn.current);
    }
  });

  const singInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(authentication, provider)
      .then((re) => {
        setFirebase_userInfo(re);
        // console.log("re", re);
      })
      .catch((err) => {
        // console.log("re-err", err);
      });
  };

  const signInWithFacebook = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(authentication, provider)
      .then((re) => {
        setFirebase_userInfo(re);
        // console.log("re", re);
      })
      .catch((err) => {
        // console.log("re-err", err);
      });
  };

  // ------------------------------------------------- DB

  // get user info by uid
  const [DB_ClientInfo, setDB_ClientInfo] = useState([]);
  const get_DB_ClientInfo_byid = () => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_vlerp__client_info__byid`, {
        client_uuid: firebase_userInfo.uid,
      })
      .then((res) => {
        setDB_ClientInfo(res.data[0]);

        if (res.data[0] != undefined) {
          // client found
          console.log("# Client Found!", res.data[0]);

          // assign value
          setSelected_ClientEmail(res.data[0].client_email);
          setSelected_ClientContact(res.data[0].client_contact);

          setSelected_ClientAddress(res.data[0].client_address);
          setSelected_ClientAddress2(res.data[0].client_address2);
          setSelected_State(res.data[0].client_state);
          setSelected_City(res.data[0].client_city);
          setSelected_Country("MY"); //res.data[0].client_country
          setSelected_Postcode(res.data[0].client_postcode);
        } else {
          // client not found
          //  new client
          console.log("# Client NOT Found!, Add new Client");

          add_DB_ClientInfo_byid();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // add new user info
  const add_DB_ClientInfo_byid = () => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/add_vlerp__client_info`, {
        client_uuid: firebase_userInfo?.uid,
        client_name: firebase_userInfo?.displayName,
        client_contact: selected_ClientContact,
        client_address: selected_ClientAddress.replaceAll("'", "`"),
        client_referer_id: p_data[0]?.referer_id,
        client_referer_name: p_data[0]?.referer_name,
        client_referer_role: p_data[0]?.referer_role,
        client_gen_by: firebase_userInfo?.uid,
        client_gen_uid: firebase_userInfo?.displayName,
        client_gen_pic: firebase_userInfo?.providerData[0]?.photoURL,
      })
      .then((res) => {
        console.log("# add_DB_ClientInfo_byid", res.data[0]);

        get_DB_ClientInfo_byid();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // add new Order ----------- Part 1/4
  const add_DB_NewOrder = () => {
    console.log("p1", selected_OrderTemplateInfo);

    //
    axios
      .post(`${REACT_APP_ECLIPSE_API}/add__vlerp__order_log__NewOrder`, {
        order_template: p_data[0]?.tp_name,
        order_template_uuid: p_data[0]?.uuid,
        order_template_from: p_data[0]?.tp_dt_from,
        order_template_until: p_data[0]?.tp_dt_until,

        clientInfo: {
          id: DB_ClientInfo.id,
          client_name: DB_ClientInfo.client_name,
          client_email: selected_ClientEmail,
          client_contact: selected_ClientContact,
          client_address: selected_ClientAddress,
          client_address2: selected_ClientAddress2,
          client_state: selected_State,
          client_city: selected_City,
          client_country: selected_Country,
          client_postcode: selected_Postcode,
        },

        refererInfo: {
          referer_id: p_data[0]?.referer_id,
          referer_name: p_data[0]?.referer_name,
          referer_contact: p_data[0]?.referer_contact,
          referer_role: p_data[0]?.referer_role,
        },

        itemInfo: selected_OrderTemplateInfo,

        courierInfo: rb_CourierFees,
        codInfo: rb_CODFees,

        step1_by: `CID: ${DB_ClientInfo.id}`,
      })
      .then((res) => {
        // setDB_ClientList(res.data);
        console.log("add__vlerp__order_log__NewOrder", res.data);
        if (res.data[5].affectedRows > 0) {
          generated_UUID.current = res.data[4][0]?.generated_UUID;
          generated_OrderID.current = res.data[4][0]?.generated_OrderID;

          set_ClientEmail_and_Address_byOrderID(
            firebase_userInfo.uid,
            selected_ClientEmail,
            selected_ClientContact,

            selected_ClientAddress,
            selected_ClientAddress2,
            selected_State,
            selected_City,
            selected_Country,
            selected_Postcode
          );

          get_BillPlz_GenerateNewUrl(
            // "9hhe9xzr", //eclipse
            "ac0swhvu", //vlerp
            p_data[0]?.tp_name,
            selected_ClientEmail,
            firebase_userInfo?.displayName,
            parseFloat(calc_GrandTotal()) * 100,
            "Order ID",
            generated_OrderID.current
          );
          alert("Successfully Created new Order");
        } else {
          alert("Failed to Created new Order! , please contact admin");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // add new Order ----------- Part 2/4
  const set_ClientEmail_and_Address_byOrderID = (
    _client_uuid,
    _client_email,
    _client_contact,

    _client_address,
    _client_address2,
    _client_state,
    _client_city,
    _client_country,
    _client_postcode
  ) => {
    console.log("p2");

    axios
      .post(`${REACT_APP_ECLIPSE_API}/set_vlerp__client_info__byClientUUID`, {
        client_uuid: _client_uuid,
        client_email: _client_email,
        client_contact: _client_contact,

        client_address: _client_address,
        client_address2: _client_address2,
        client_state: _client_state,
        client_city: _client_city,
        client_country: _client_country,
        client_postcode: _client_postcode,
      })
      .then((res) => {
        console.log("# set_vlerp__client_info__byClientUUID", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // add new Order ----------- Part 3/4
  const get_BillPlz_GenerateNewUrl = (
    _collection_id,
    _description,
    _email,
    _name,
    _amount,
    _reference_1_label,
    _reference_1
  ) => {
    console.log("p3");

    console.log("_collection_id", _collection_id);
    console.log("_description", _description);
    console.log("_email", _email);
    console.log("_name", _name);
    console.log("_amount", _amount);
    console.log("_reference_1_label", _reference_1_label);
    console.log("_reference_1", _reference_1);

    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/add__api_billplz__vlerp__CreateNew_sendbox`,
        {
          // collection_id: "9hhe9xzr", //"9hhe9xzr",
          // description: "sample desc", //"Maecenas eu placerat ante.",
          // email: "api@billplz.com", //"api@billplz.com",
          // name: "saraaK", //"Sara",
          // amount: 777888, // "77777",
          // reference_1_label: "Product Invoice", // "Product Invoice",
          // reference_1: "www.googlecom", //"www.googlecom",

          collection_id: _collection_id, //"9hhe9xzr",
          description: _description, //"Maecenas eu placerat ante.",
          email: _email, //"api@billplz.com",
          name: _name, //"Sara",
          amount: _amount, // "77777",
          reference_1_label: _reference_1_label, // "Product Invoice",
          reference_1: _reference_1, //"www.googlecom",
        }
      )
      .then((res) => {
        console.log("# get_BillPlz_GenerateNewUrl", res.data);
        set_BillPlz_GenerateNewUrl_byOrderID(
          res.data.url,
          generated_OrderID.current
        );
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // add new Order ----------- Part 4/4
  const set_BillPlz_GenerateNewUrl_byOrderID = (_billplz_url, _order_id) => {
    console.log("p4");

    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/set_vlerp__order_log__billplzURL__byOrderId`,
        {
          billplz_url: _billplz_url,
          order_id: _order_id,
        }
      )
      .then((res) => {
        console.log("# set_BillPlz_GenerateNewUrl_byOrderID", res.data);

        // open invoice for client make payment
        window.open(`#/invoice/${generated_UUID.current}`);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // --------------------------------------- function
  const calc_SubTotal = () => {
    return selected_OrderTemplateInfo
      .reduce(
        (acc, x) =>
          acc +
          parseFloat(x?.selected_Item_Qty) *
            parseFloat(x?.selected_Item_PricePerUnit),
        0
      )
      .toFixed(2);
  };
  const calc_CourierFee = () => {
    return rb_CourierFees != undefined
      ? parseFloat(rb_CourierFees)?.toFixed(2)
      : 0;
  };
  const calc_COD = () => {
    return rb_CODFees != undefined ? parseFloat(rb_CODFees)?.toFixed(2) : 0;
  };

  const calc_GrandTotal = () => {
    return (
      parseFloat(calc_SubTotal()) +
      parseFloat(calc_CourierFee()) +
      +parseFloat(calc_COD())
    ).toFixed(2);
  };

  // --------------------------------------- Radio
  const [rb_CourierFees, setRb_CourierFees] = React.useState("");
  const [rb_CourierRegion, setRb_CourierRegion] = React.useState("");

  const hc_Rb_CourierFees = (event) => {
    setRb_CourierFees(event.target.value);
  };
  const [rb_CODFees, setRb_CODFees] = React.useState(0);

  const hc_Rb_CODFees = (event) => {
    setRb_CODFees(event.target.value);
  };

  // --------------------------------------- Number input
  const handlers = useRef();

  // --------------------------------------- Global variable

  const [selected_OrderTemplateInfo, setSelected_OrderTemplateInfo] =
    useState(p_data);

  const [selected_ClientEmail, setSelected_ClientEmail] = useState("");
  const [selected_ClientContact, setSelected_ClientContact] = useState("");
  const [selected_ClientAddress, setSelected_ClientAddress] = useState("");
  const [selected_ClientAddress2, setSelected_ClientAddress2] = useState("");
  const [selected_State, setSelected_State] = useState("");
  const [selected_City, setSelected_City] = useState("");
  const [selected_Country, setSelected_Country] = useState("MY");
  const [selected_Postcode, setSelected_Postcode] = useState("");

  const generated_OrderID = useRef("");
  const generated_UUID = useRef("");
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  useEffect(() => {
    console.log("contextValue", contextValue);
  }, []);

  useEffect(() => {
    setRb_CourierFees(p_data[0]?.courier_fee);
    setRb_CourierRegion(p_data[0]?.courier_region);
    console.log("useEffect__p_data", p_data);
  }, [p_data]);

  // if user Signed in Firebase Auth
  useEffect(() => {
    if (firebase_user_SignedIn) {
      console.log("# firebase_user_SignedIn", firebase_user_SignedIn);

      console.log("# firebase_userInfo", firebase_userInfo);

      // if signin firebase, get user info, if empty create new, get user info again
      if (firebase_user_SignedIn == true) {
        get_DB_ClientInfo_byid();
      }
    }
  }, [firebase_user_SignedIn]);

  return (
    <>
      <COM_NavBar />
      {/* {console.log("p_data[0]", p_data[0])}
      {console.log("p_data", p_data)} */}
      <div className="po_1_co__itemContainer">
        <div className="global_grid_2column_container_2080">
          <h3>Template</h3>
          <h1>{p_data[0]?.tp_name}</h1>
          <h3>Promotion Date</h3>
          <h3>{`${p_data[0]?.tp_dt_from} - ${p_data[0]?.tp_dt_until}`}</h3>

          <h3>Referer Name</h3>
          <h3>{`[${p_data[0]?.referer_id}] ${p_data[0]?.referer_name}`}</h3>
        </div>
        <div className="global_grid_2column_container_2080">
          <div>
            <h3>Client Name</h3>
            <div>{`${
              firebase_user_SignedIn == true
                ? `Client ID : ${firebase_userInfo?.uid}`
                : "<Login Required>"
            }`}</div>
          </div>
          <div>
            <h3>{`${
              firebase_user_SignedIn == true
                ? firebase_userInfo?.displayName
                : "<Login Required>"
            }`}</h3>
          </div>

          <h3>Client Email</h3>
          <div>
            <TextField
              fullWidth
              // autoFocus
              margin="dense"
              placeholder="me@example.com"
              // id="name"
              value={selected_ClientEmail}
              onChange={(event) =>
                setSelected_ClientEmail(event.target.value.replaceAll("'", "`"))
              }
              // label=" "

              type="text"
              variant="standard"
            />
          </div>
          <h3>Client Contact</h3>
          <div>
            <TextField
              fullWidth
              // autoFocus
              margin="dense"
              // id="name"
              value={selected_ClientContact}
              onChange={(event) =>
                setSelected_ClientContact(
                  event.target.value.replace(/[^0-9]/g, "")
                )
              }
              // label=" "
              placeholder="Client Contact"
              type="text"
              variant="standard"
            />
          </div>
          <h3>Client Mailing Address 1</h3>
          <div>
            <TextField
              fullWidth
              // autoFocus
              margin="dense"
              // id="name"
              value={selected_ClientAddress}
              onChange={(event) =>
                setSelected_ClientAddress(
                  event.target.value.replaceAll("'", "`")
                )
              }
              placeholder="house no./lot no./floor and building name"
              helperText="ie. 66 Jalan Kenari"
              type="text"
              variant="standard"
            />
          </div>
          <h3>Client Mailing Address 2</h3>
          <div>
            <TextField
              fullWidth
              // autoFocus
              margin="dense"
              // id="name"
              value={selected_ClientAddress2}
              onChange={(event) =>
                setSelected_ClientAddress2(
                  event.target.value.replaceAll("'", "`")
                )
              }
              placeholder="number, street name/district"
              helperText="ie. Taman Kenari"
              type="text"
              variant="standard"
            />
          </div>
          <h3>Postcode</h3>
          <div>
            <TextField
              fullWidth
              // autoFocus
              margin="dense"
              // id="name"
              value={selected_Postcode}
              onChange={(event) =>
                setSelected_Postcode(
                  event.target.value.replaceAll("'", "`").replace(/[^0-9]/g, "")
                )
              }
              placeholder="postcode"
              helperText="ie. 43000 "
              type="number"
              variant="standard"
            />
          </div>
          <h3>City</h3>
          <div>
            <Select
              value={selected_City}
              onChange={setSelected_City}
              data={[
                { value: "Johor", label: "Johor" },
                { value: "Kedah", label: "Kedah" },
                { value: "Kelantan", label: "Kelantan" },
                { value: "Negeri Sembilan", label: "Negeri Sembilan" },
                { value: "Pahang", label: "Pahang" },

                { value: "Perak", label: "Perak" },
                { value: "Perlis", label: "Perlis" },
                { value: "Selangor", label: "Selangor" },
                { value: "Terengganu", label: "Terengganu" },
                { value: "Kuala Lumpur", label: "Kuala Lumpur" },

                { value: "Labuan", label: "Labuan" },
                { value: "Putrajaya", label: "Putrajaya" },
                { value: "Melaka", label: "Melaka" },
                { value: "Pulau Pinang", label: "Pulau Pinang" },
                { value: "Sabah", label: "Sabah" },

                { value: "Sarawak", label: "Sarawak" },
              ].sort((a, b) => {
                return a.value - b.value;
              })}
            />
            {selected_City ? selected_City : "na"}
            {/* <TextField
              fullWidth
              // autoFocus
              margin="dense"
              // id="name"
              value={selected_City}
              onChange={(event) =>
                setSelected_City(event.target.value.replaceAll("'", "`"))
              }
              placeholder="locality name"
              helperText="ie. KAJANG "
              type="text"
              variant="standard"
            /> */}
          </div>
          <h3>State</h3>
          <div>
            <TextField
              fullWidth
              // autoFocus
              margin="dense"
              // id="name"
              value={selected_State}
              onChange={(event) =>
                setSelected_State(event.target.value.replaceAll("'", "`"))
              }
              placeholder="state name"
              helperText="ie. SELANGOR "
              type="text"
              variant="standard"
            />
          </div>
          <h3>Country</h3>
          <div>
            <TextField
              disabled
              fullWidth
              // autoFocus
              margin="dense"
              // id="name"
              value={selected_Country}
              onChange={(event) =>
                setSelected_Country(event.target.value.replaceAll("'", "`"))
              }
              placeholder="country name"
              helperText="ie. MY "
              type="text"
              variant="standard"
            />
          </div>
        </div>

        <div className="global_grid_2column_container_2080">
          <h3>Courier Fees</h3>
          <div>
            <FormControl disabled>
              <FormLabel id="demo-controlled-radio-buttons-group">
                <h3>{`Selected : RM ${rb_CourierFees}`}</h3>
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={rb_CourierFees}
                onChange={hc_Rb_CourierFees}
              >
                <FormControlLabel
                  value={`${rb_CourierFees}`}
                  control={<Radio />}
                  label={`${rb_CourierRegion} (RM ${rb_CourierFees})`}
                />
                {/* <FormControlLabel
                  value="10"
                  control={<Radio />}
                  label="East Malaysia (RM 10.00)"
                />
                <FormControlLabel
                  value="14"
                  control={<Radio />}
                  label="West Malaysia (RM 14.00)"
                /> */}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className="global_grid_2column_container_2080">
          <div>
            <h3>COD Fees</h3>
            CASH ON DELIVERY
          </div>
          <div>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                <h3>{`Selected : RM ${rb_CourierFees}`}</h3>
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={rb_CODFees}
                onChange={hc_Rb_CODFees}
              >
                <FormControlLabel
                  value="15.60"
                  control={<Radio />}
                  label="East Malaysia (RM 15.60)"
                />
                <FormControlLabel
                  value="7.50"
                  control={<Radio />}
                  label="West Malaysia (RM 7.50)"
                />
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="NO NEED"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>
      <br />
      <div className="po_1_co__itemContainer">
        <h3>Item(s)</h3>
        <div />
        <div />
        <div className="global_grid_4column_container_cust002">
          <h4 className="global_textAlignCenter">Product Description</h4>
          <h4 className="global_textAlignCenter">Price Per Unit (RM)</h4>
          <h4 className="global_textAlignCenter">Min Order Qty</h4>
          <h4 className="global_textAlignCenter">Order Qty</h4>
          <h4 className="global_textAlignCenter">Sub Total (RM)</h4>

          {/* item */}
          {selected_OrderTemplateInfo.map((x) => (
            <>
              <div className="global_grid_2column_container_cust003">
                <img
                  style={{ width: "150px" }}
                  src={`${REACT_APP_ECLIPSE_API}/uploads/vlerp/product/${x?.item_id}__product001.jpg`}
                />

                <div>
                  <h3>{`[${x?.item_id}] ${x?.item_name}`}</h3>
                  <h5>{x?.item_desc}</h5>
                </div>
              </div>
              <h3 className="global_textAlignCenter ">
                {parseFloat(x?.selected_Item_PricePerUnit).toFixed(2)}
              </h3>
              <h3 className="global_textAlignCenter">
                {parseFloat(x?.selected_Item_MinOrderQty).toFixed(2)}
              </h3>
              <div className="global_flex_row_cc">
                <NumberInput
                  defaultValue={0}
                  placeholder="0"
                  // label="Your age"
                  required
                  min={0}
                  // hideControls

                  onChange={(value) => {
                    if (
                      // parseFloat(event.target.value) >=
                      //   parseFloat(x.selected_Item_MinOrderQty) ||
                      parseFloat(value) != "0" ||
                      value != undefined ||
                      value != null
                    ) {
                      // setSelected_OrderQty(event.target.value)
                      x.selected_Item_Qty = value;
                      setSelected_OrderTemplateInfo([
                        ...selected_OrderTemplateInfo,
                      ]);
                    } else {
                      // console.log("event.target.value", event.target.value);
                      // console.log(
                      //   "compare",
                      //   parseFloat(event.target.value).toFixed(2) >=
                      //     parseFloat(x.selected_Item_MinOrderQty).toFixed(2)
                      // );
                      // console.log("event.target.value", event.target.value);
                      // console.log(
                      //   "x.selected_Item_MinOrderQty",
                      //   x.selected_Item_MinOrderQty
                      // );
                      // alert(
                      //   `Quantity Product must > ${x.selected_Item_MinOrderQty}`
                      // );
                    }
                  }}
                />
              </div>

              {/* <h3>{x?.selected_Item_MinOrderQty}</h3> */}
              <h3 className="global_textAlignCenter">
                {(
                  parseFloat(x?.selected_Item_PricePerUnit) *
                  parseFloat(x?.selected_Item_Qty)
                ).toFixed(2)}
              </h3>
            </>
          ))}
          {/* SubTotal Price */}
          <div />
          <div />
          <h3 className="global_textAlignCenter">
            {`${selected_OrderTemplateInfo.reduce(
              (acc, x) => acc + parseFloat(x?.selected_Item_Qty),
              0
            )} Item(s)`}
          </h3>
          <h3 className="global_textAlignCenter">Sub Total: </h3>
          <h3 className="global_textAlignCenter">
            <u>{`RM ${calc_SubTotal()}`}</u>
          </h3>
          {/* Courier Fee */}
          <div />
          <div />
          <div />
          <h3 className="global_textAlignCenter">Courier Fee</h3>
          <h3 className="global_textAlignCenter">{`RM ${calc_CourierFee()}`}</h3>
          {/* Courier Fee */}
          <div />
          <div />
          <div />
          <h3 className="global_textAlignCenter">COD Fee</h3>
          <h3 className="global_textAlignCenter">{`RM ${calc_COD()}`}</h3>
          {/* Total  */}
          <div />
          <div />
          <div />
          <h3 className="global_textAlignCenter">Grand Total</h3>
          <h3 className="global_textAlignCenter">
            <u>{`RM ${calc_GrandTotal()}`}</u>
          </h3>
        </div>
        {/* confirm order */}
        <Button
          size="large"
          fullWidth
          variant="contained"
          color="success"
          onClick={() => {
            if (firebase_user_SignedIn != true) {
              alert("Login Required");
            } else {
              // ---------------------------------------- input validation
              if (
                selected_ClientEmail == "" ||
                selected_ClientEmail == undefined ||
                selected_ClientEmail.includes("@") == false ||
                selected_ClientEmail.includes(".") == false
              ) {
                alert("Please Enter a valid Email");
                return;
              }
              if (
                selected_ClientContact == "" ||
                selected_ClientContact == undefined
              ) {
                alert("Please Enter a valid Phone");
                return;
              }
              if (
                selected_ClientAddress == "" ||
                selected_ClientAddress == undefined
              ) {
                alert("Please Enter a valid Mailing Address");
                return;
              }
              if (
                selected_ClientAddress2 == "" ||
                selected_ClientAddress2 == undefined
              ) {
                alert("Please Enter a valid Mailing Address 2");
                return;
              }
              if (selected_Postcode == "" || selected_Postcode == undefined) {
                alert("Please Enter a valid Postcode");
                return;
              }
              if (selected_City == "" || selected_City == undefined) {
                alert("Please Enter a valid City");
                return;
              }
              if (selected_State == "" || selected_State == undefined) {
                alert("Please Enter a valid State");
                return;
              }
              if (selected_Country == "" || selected_Country == undefined) {
                alert("Please Enter a valid Country");
                return;
              }
              if (calc_GrandTotal() < 1) {
                alert("Grand Total must greater then rm 0");
                return;
              }
              if (calc_SubTotal() < 1) {
                alert("Sub Total must greater then rm 0");
                return;
              }
              if (rb_CourierFees < 1) {
                alert("Courier Fees must greater then rm 0");
                return;
              }

              // --------------------------------------- user confirm
              if (window.confirm("Confirm ?")) {
                // console.log("selected_OrderQty", selected_OrderTemplateInfo);
                add_DB_NewOrder();
              }
            }
          }}
        >
          Order Confirm !
        </Button>
      </div>
    </>
  );
}
