import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";
import moment from "moment";
import axios from "axios";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

import COM_NavBar from "../Component/COM_NavBar";

import { UserContext } from "../UserContext";

export default function Page_Main() {
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  useEffect(() => {
    console.log("contextValue", contextValue);
  }, []);

  return (
    <>
      <COM_NavBar />
      <br />
      <br />
      <br />
      <br />
      <br />
      Client Page_Main
      <Button
        onClick={() => {
          window.location.href = `#/MyOrder`;
        }}
      >
        My Order
      </Button>
      <Button
        onClick={() => {
          window.open(`#/order/77790c86af6511ec8618d0278801e8fd`);
        }}
      >
        to Order Test
      </Button>
    </>
  );
}
