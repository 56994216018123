import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
  useMemo,
} from "react";
import moment from "moment";
import axios from "axios";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";

import { FcGoogle as GoogleIcon } from "react-icons/fc";
import { AiFillMail } from "react-icons/ai";
import { FaFacebookSquare as FacebookIcon } from "react-icons/fa";
// -------------------------------------------------  firebase - 1/2
import { authentication } from "../firebase-config";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from "@mui/material";

import COM_NavBar from "../Component/COM_NavBar";

import { UserContext } from "../UserContext";
import { WindowSharp } from "@mui/icons-material";

//  -------------------------- Material Table
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function Page_Main() {
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  // ------------------------------------------------- DB

  //
  const [DB_ClientInfo, setDB_ClientInfo] = useState([]);
  const get_DB_ClientInfo_byUUID = (_client_uuid) => {
    console.log("_client_uuid", _client_uuid);
    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_vlerp__client_info__byid`, {
        client_uuid: _client_uuid,
      })
      .then((res) => {
        setDB_ClientInfo(res.data);
        console.log("get_DB_ClientInfo_byUUID", res.data);

        // generate table

        get_DB_Order_GroupOrderId_ByClientID(res.data[0]?.id);
        get_DB_Order_GroupOrderId_ByClientID_Grouped(res.data[0]?.id);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //
  const [DB_Order, setDB_Order] = useState([]);
  const get_DB_Order_byUUID = (_UUID) => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_vlerp__order_log__byUUID`, {
        UUID: _UUID,
      })
      .then((res) => {
        setDB_Order(res.data);
        // console.log("_UUID:id", _UUID);
        // console.log("get_DB_Order_byUUID", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //
  const [
    DB_Order_GroupOrderId_ByClientID,
    setDB_Order_GroupOrderId_ByClientID,
  ] = useState([]);
  const get_DB_Order_GroupOrderId_ByClientID = (_client_id) => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/get_vlerp__order_log__GroupOrderId__byClientId`,
        {
          client_id: _client_id,
          grouped: false,
        }
      )
      .then((res) => {
        setDB_Order_GroupOrderId_ByClientID(res.data);
        console.log("get_DB_Order_GroupOrderId_ByClientID", res.data);

        // prepare 1
        let collection = res.data?.map((x) => {
          return x.uuid;
        });

        console.log("collection uuid  ", collection);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  //
  const [
    DB_Order_GroupOrderId_ByClientID_Grouped,
    setDB_Order_GroupOrderId_ByClientID_Grouped,
  ] = useState([]);
  const get_DB_Order_GroupOrderId_ByClientID_Grouped = (_client_id) => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/get_vlerp__order_log__GroupOrderId__byClientId`,
        {
          client_id: _client_id,
          grouped: true,
        }
      )
      .then((res) => {
        setDB_Order_GroupOrderId_ByClientID_Grouped(res.data);
        console.log("get_DB_Order_GroupOrderId_ByClientID_Grouped", res.data);

        // prepare 1
        let collection = res.data?.map((x) => {
          return x.uuid;
        });

        console.log("collection uuid  ", collection);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // ------------------------------------------------- Table Columns

  const columns_DB_Data_byorderid = [
    {
      title: "Invoice",
      field: "uuid",
      minWidth: "1px",
      render: (rowData) => (
        <>
          <div
            className="btn_normal"
            onClick={() => {
              window.open(`#/invoice/${rowData.uuid}`);
            }}
          >
            <h3>View Invoice</h3>
          </div>
        </>
      ),
    },
    {
      title: "Payment Status",
      field: "payment_status",
      minWidth: "1px",
      render: (rowData) => (
        <>{rowData.payment_status == "true" ? "Successful" : "Pending"}</>
      ),
    },
    {
      title: "Order Desc",
      field: "order_template",
      minWidth: "300px",
    },
    {
      title: "Order Desc",
      field: "order_template",
      minWidth: "300px",
      render: (rowData) => (
        <>
          {DB_Order_GroupOrderId_ByClientID?.filter((x) => {
            return x.order_id == rowData.order_id;
          })?.map((x) => (
            <>
              <div>{`${x.item_name} x ${x.selected_ItemQty}`}</div>
            </>
          ))}
        </>
      ),
    },

    {
      title: "Promotion End",
      field: "DurationRemain",
      minWidth: "120px",
    },

    {
      title: "Order Status",
      field: "step4",
      minWidth: "1px",
    },
  ];

  useEffect(() => {
    console.log("contextValue", contextValue);

    get_DB_ClientInfo_byUUID(
      contextValue?.contextValue?.firebase_userInfo?.uid
    );
  }, []);

  useEffect(() => {}, []);

  return (
    <>
      <COM_NavBar />
      <br />
      <br />
      <br />
      <br />
      <br />
      {DB_ClientInfo?.id}
      {DB_ClientInfo.map((x) => (
        <>
          <div>{x.id}</div>
          <div>{x.client_gen_uid}</div>
          <div>{x.client_contact}</div>
          <div>{x.client_email}</div>
          <div>{x.client_address}</div>
          <div>{x.client_address2}</div>
          <div>{x.client_city}</div>
          <div>{x.client_state}</div>
          <div>{x.client_country}</div>
          <div>{x.client_uuid}</div>
        </>
      ))}

      <h1>My Order</h1>

      <MaterialTable
        style={{
          MuiTablePaginationRoot: "gold",
          color: "black",
          // background: "rgba(0, 0, 100, .0)",
          backgroundImage:
            "linear-gradient(to bottom right, rgb(255, 255, 255), rgb(255, 255, 255))",
          boxShadow: "0 8px 32px 0 rgba( 0, 0, 0, 0.4 )",
          backdropFilter: "blur( 11.0px )",
          "&::webkit-backdrop-filter": {
            webkitBackdropFilter: "blur( 18.0px )",
          },
        }}
        title="Order History "
        columns={columns_DB_Data_byorderid}
        data={DB_Order_GroupOrderId_ByClientID_Grouped}
        options={{
          headerStyle: {
            background: "rgba(255, 255, 255, .2)",
            border: "none",
          },
          exportButton: true,
          rowStyle: (rowData) => ({
            // backgroundColor: "#1a0000",
            // color: "#ffffff",
            fontSize: 12,
          }),
          filtering: true,
          // grouping: true,
          // exportAllData: true,
          pageSize: 5,
          pageSizeOptions: [10, 20, 50, 100],
          paginationType: "stepped",
          // actionsColumnIndex: -1
          // fixedColumns: {
          //     left: 1,
          // }
          // exportMenu: [
          //   {
          //     label: "Export PDF",
          //     exportFunc: (cols, datas) =>
          //       ExportPdf(cols, datas, "myPdfFileName"),
          //   },
          //   {
          //     label: "Export CSV",
          //     exportFunc: (cols, datas) =>
          //       ExportCsv(cols, datas, "myCsvFileName"),
          //   },
          // ],
        }}
        onRowClick={(evt, selectedRow) => {
          // alert(selectedRow.order_id);
          // selected_Order_Id.current = selectedRow.order_id;
          // hd_DialogOpen();
        }}
        // actions={[
        //   {
        //     icon: "save",
        //     tooltip: "Save User",
        //     onClick: (event, rowData) => {
        //       // handleClickOpen_1_2();
        //       // getDB_patroninfo_byid(rowData.id);
        //       // alert(rowData.id);
        //     },
        //   },
        // ]}
        components={{
          Action: (props) => (
            <>
              {/* <Button
              classes={{ root: classes.button }}
              onClick={(event) => props.action.onClick(event, props.data)}
              >
                <div style={{ color: "gold" }}>DONE</div>
              </Button>
              <Button
              classes={{ root: classes.button }}
              onClick={(event) => props.action.onClick(event, props.data)}
              >
                <div style={{ color: "#2bc3ff" }}>EDIT</div>
              </Button> */}
            </>
          ),
        }}
      />
    </>
  );
}
